<template>
  <CCard>
    <CCardHeader>
      <slot name="header"> <CIcon name="cil-grid" /> {{ caption }} </slot>
    </CCardHeader>
    <CCardBody>
      <CAlert show color="info"
        >You must be owner of the account to be able to manage it. If you do not
        see your account in the following list, make sure you are identified as
        an owner. Contact OneCloud team if necessary.</CAlert
      >
      <div v-if="loading" align="center">
        <img src="/img/tenor.gif" width="50" />
      </div>
      <div v-else>
        <CDataTable
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :items="items"
          :fields="fields"
          items-per-page-select
          :items-per-page="small ? 20 : 10"
          :dark="dark"
          pagination
          clickableRows
          tableFilter
          sorter
          @row-clicked="rowClicked"
        >
          <template #manage="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="manage(item)"
                >{{ item.manage ? "Remediate" : "View" }}</CButton
              >
            </td>
          </template>
        </CDataTable>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "TableAccountList",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["AccountAlias", "AccountId", "manage"];
      }
    },
    caption: {
      type: String,
      default: "Table"
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    loading: Boolean
  },
  methods: {
    rowClicked(item) {
      this.manage(item);
    },
    async manage(item) {
      this.$root.currentAccount = {
        accountId: item.AccountId,
        alias: item.AccountAlias,
        accessStatus: "VALIDATED"
      };
      this.$router.push({
        path: "/accounts/" + item.AccountId + "/info"
      });
    }
  }
};
</script>
